<template>
  <v-container class="cuny-sandbox-container">
    <div class="">
      <div class="test-pool">
        <div class="test-pool-row">
          <div class="pool-group">
            <h4 class="group-title">Tests</h4>
            <div class="group-items">
              <v-btn rounded color="primary" class="no-text-transform" small disabled>
                <stemble-latex content="DNP" />
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="fehlingButtonDisabled"
                @click="
                  fehlingTestVisible = true;
                  fehlingButtonDisabled = true;
                  testCounter += 1;
                "
              >
                Fehling's
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="tollenButtonDisabled"
                @click="
                  tollenTestVisible = true;
                  tollenButtonDisabled = true;
                  testCounter += 1;
                "
              >
                Tollen's
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="iodoformButtonDisabled"
                @click="
                  iodoformTestVisible = true;
                  iodoformButtonDisabled = true;
                  testCounter += 1;
                "
              >
                Iodoform
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="lucasButtonDisabled"
                @click="
                  lucasTestVisible = true;
                  lucasButtonDisabled = true;
                  testCounter += 1;
                "
              >
                Lucas
              </v-btn>
              <v-btn
                rounded
                color="primary"
                class="no-text-transform"
                small
                :disabled="bordwellWellmanButtonDisabled"
                @click="
                  bordwellWellmanTestVisible = true;
                  bordwellWellmanButtonDisabled = true;
                  testCounter += 1;
                "
              >
                Bordwell-Wellman
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="cuny-sandbox-table-wrapper">
      <table class="cuny-sandbox-table">
        <tbody>
          <tr>
            <th colspan="7" class="table-heading">Test Tubes</th>
          </tr>
          <tr>
            <th style="width: 150px"></th>
            <th class="test-tube-heading">Tube 1</th>
            <th class="test-tube-heading">Tube 2</th>
            <th class="test-tube-heading">Tube 3</th>
            <th class="test-tube-heading">Tube 4</th>
            <th class="test-tube-heading">Tube 5</th>
            <th class="test-tube-heading">Tube 6</th>
          </tr>
          <tr class="zebra-row">
            <td class="test-row-heading">DNP</td>
            <td v-for="(tube, index) in tubes" :key="tube.dnp + index" class="text-sm">
              <v-icon aria-role="presentation" :class="['table-icon', tube.dnp]">
                {{ 'mdi-' + tube.dnp }}
              </v-icon>
            </td>
          </tr>
          <tr v-if="fehlingTestVisible" class="zebra-row">
            <td class="test-row-heading">Fehling's</td>
            <td v-for="(tube, index) in tubes" :key="tube.fehling + index" class="text-sm">
              <v-icon aria-role="presentation" :class="['table-icon', tube.fehling]">
                {{ 'mdi-' + tube.fehling }}
              </v-icon>
            </td>
          </tr>
          <tr v-if="tollenTestVisible" class="zebra-row">
            <td class="test-row-heading">Tollen's</td>
            <td v-for="(tube, index) in tubes" :key="tube.tollen + index" class="text-sm">
              <v-icon aria-role="presentation" :class="['table-icon', tube.tollen]">
                {{ 'mdi-' + tube.tollen }}
              </v-icon>
            </td>
          </tr>
          <tr v-if="iodoformTestVisible" class="zebra-row">
            <td class="test-row-heading">Iodoform</td>
            <td v-for="(tube, index) in tubes" :key="tube.iodoform + index" class="text-sm">
              <v-icon aria-role="presentation" :class="['table-icon', tube.iodoform]">
                {{ 'mdi-' + tube.iodoform }}
              </v-icon>
            </td>
          </tr>
          <tr v-if="lucasTestVisible" class="zebra-row">
            <td class="test-row-heading">Lucas</td>
            <td v-for="(tube, index) in tubes" :key="tube.lucas + index" class="text-sm">
              <v-icon aria-role="presentation" :class="['table-icon', tube.lucas]">
                {{ 'mdi-' + tube.lucas }}
              </v-icon>
            </td>
          </tr>
          <tr v-if="bordwellWellmanTestVisible" class="zebra-row">
            <td class="test-row-heading">Bordwell-Wellman</td>
            <td v-for="(tube, index) in tubes" :key="tube.bordwellWellman + index" class="text-sm">
              <v-icon aria-role="presentation" :class="['table-icon', tube.bordwellWellman]">
                {{ 'mdi-' + tube.bordwellWellman }}
              </v-icon>
            </td>
          </tr>
          <tr>
            <th colspan="7">Total Number of Tests: {{ testCounter }}</th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="cuny-sandbox-table-wrapper">
      <table class="cuny-sandbox-table flexible">
        <tbody>
          <tr class="response-table-heading">
            <th colspan="7" class="table-heading">Your Analysis</th>
          </tr>
          <tr>
            <th></th>
            <th class="test-tube-heading text-left">Tube 1</th>
            <th class="test-tube-heading text-left">Tube 2</th>
            <th class="test-tube-heading text-left">Tube 3</th>
            <th class="test-tube-heading text-left">Tube 4</th>
            <th class="test-tube-heading text-left">Tube 5</th>
            <th class="test-tube-heading text-left">Tube 6</th>
          </tr>
          <tr>
            <td class="test-row-heading">Type</td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type1"
                :items="typeOptions"
                label="Type"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type2"
                :items="typeOptions"
                label="Type"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type3"
                :items="typeOptions"
                label="Type"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type4"
                :items="typeOptions"
                label="Type"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type5"
                :items="typeOptions"
                label="Type"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.type6"
                :items="typeOptions"
                label="Type"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
          </tr>
          <tr>
            <td class="test-row-heading">Compound</td>
            <td class="select-cell">
              <v-select
                v-model="inputs.compound1"
                :items="chooseCompoundBasedOnType(inputs.type1)"
                label="Compound"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.compound2"
                :items="chooseCompoundBasedOnType(inputs.type2)"
                label="Compound"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.compound3"
                :items="chooseCompoundBasedOnType(inputs.type3)"
                label="Compound"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.compound4"
                :items="chooseCompoundBasedOnType(inputs.type4)"
                label="Compound"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.compound5"
                :items="chooseCompoundBasedOnType(inputs.type5)"
                label="Compound"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
            <td class="select-cell">
              <v-select
                v-model="inputs.compound6"
                :items="chooseCompoundBasedOnType(inputs.type6)"
                label="Compound"
                dense
                outlined
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
                <template #selection="{item}">
                  <span class="select-text">{{ item.text }}</span>
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div align="right">
      <v-btn rounded color="primary" class="no-text-transform mt-5" small @click="reset()">
        Reset
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';

export default {
  name: 'CUNYSandboxTubes',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      testCounter: 1,
      fehlingTestVisible: false,
      fehlingButtonDisabled: false,
      tollenTestVisible: false,
      tollenButtonDisabled: false,
      iodoformTestVisible: false,
      iodoformButtonDisabled: false,
      lucasTestVisible: false,
      lucasButtonDisabled: false,
      bordwellWellmanTestVisible: false,
      bordwellWellmanButtonDisabled: false,
      inputs: {
        type1: null,
        type2: null,
        type3: null,
        type4: null,
        type5: null,
        type6: null,
        compound1: null,
        compound2: null,
        compound3: null,
        compound4: null,
        compound5: null,
        compound6: null,
      },
      typeOptions: [
        {text: 'Carbonyl', value: 'Carbonyl'},
        {text: 'Alcohol', value: 'Alcohol'},
      ],
      carbonylCompoundOptions: [
        {text: 'Aldehyde', value: 'aldehyde'},
        {text: 'Ketone', value: 'ketone'},
        {text: 'Methyl Ketone', value: 'methyl'},
      ],
      alcoholCompoundOptions: [
        {text: 'Primary', value: 'primary'},
        {text: 'Secondary', value: 'secondary'},
        {text: 'Tertiary', value: 'tertiary'},
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    tubes() {
      let tube1 = {};
      let tube2 = {};
      let tube3 = {};
      let tube4 = {};
      let tube5 = {};
      let tube6 = {};

      // Aldehyde
      const option1 = {
        dnp: 'check-bold',
        fehling: 'check-bold',
        tollen: 'check-bold',
        iodoform: 'close-thick',
        lucas: 'minus',
        bordwellWellman: 'minus',
      };

      // Ketone
      const option2 = {
        dnp: 'check-bold',
        fehling: 'close-thick',
        tollen: 'close-thick',
        iodoform: 'close-thick',
        lucas: 'minus',
        bordwellWellman: 'minus',
      };

      // Methyl Ketone
      const option3 = {
        dnp: 'check-bold',
        fehling: 'close-thick',
        tollen: 'close-thick',
        iodoform: 'check-bold',
        lucas: 'minus',
        bordwellWellman: 'minus',
      };

      // Primary
      const option4 = {
        dnp: 'close-thick',
        fehling: 'minus',
        tollen: 'minus',
        iodoform: 'minus',
        lucas: 'close-thick',
        bordwellWellman: 'check-bold',
      };

      // Secondary
      const option5 = {
        dnp: 'close-thick',
        fehling: 'minus',
        tollen: 'minus',
        iodoform: 'minus',
        lucas: 'check-bold',
        bordwellWellman: 'check-bold',
      };

      // Tertiary
      const option6 = {
        dnp: 'close-thick',
        fehling: 'minus',
        tollen: 'minus',
        iodoform: 'minus',
        lucas: 'check-bold',
        bordwellWellman: 'close-thick',
      };

      if (this.versionNumber.value === 1) {
        tube1 = option4;
        tube2 = option2;
        tube3 = option6;
        tube4 = option1;
        tube5 = option5;
        tube6 = option3;
      } else if (this.versionNumber.value === 2) {
        tube1 = option3;
        tube2 = option5;
        tube3 = option2;
        tube4 = option4;
        tube5 = option6;
        tube6 = option1;
      } else if (this.versionNumber.value === 3) {
        tube1 = option2;
        tube2 = option6;
        tube3 = option3;
        tube4 = option1;
        tube5 = option5;
        tube6 = option4;
      } else if (this.versionNumber.value === 4) {
        tube1 = option5;
        tube2 = option1;
        tube3 = option4;
        tube4 = option3;
        tube5 = option6;
        tube6 = option2;
      } else if (this.versionNumber.value === 5) {
        tube1 = option6;
        tube2 = option3;
        tube3 = option5;
        tube4 = option2;
        tube5 = option1;
        tube6 = option4;
      } else if (this.versionNumber.value === 6) {
        tube1 = option1;
        tube2 = option6;
        tube3 = option4;
        tube4 = option2;
        tube5 = option5;
        tube6 = option3;
      } else if (this.versionNumber.value === 7) {
        tube1 = option1;
        tube2 = option6;
        tube3 = option4;
        tube4 = option2;
        tube5 = option5;
        tube6 = option3;
      } else if (this.versionNumber.value === 8) {
        tube1 = option3;
        tube2 = option2;
        tube3 = option4;
        tube4 = option5;
        tube5 = option6;
        tube6 = option1;
      } else if (this.versionNumber.value === 9) {
        tube1 = option4;
        tube2 = option1;
        tube3 = option5;
        tube4 = option3;
        tube5 = option6;
        tube6 = option2;
      } else if (this.versionNumber.value === 10) {
        tube1 = option2;
        tube2 = option1;
        tube3 = option5;
        tube4 = option4;
        tube5 = option3;
        tube6 = option6;
      }

      return [tube1, tube2, tube3, tube4, tube5, tube6];
    },
  },
  methods: {
    chooseCompoundBasedOnType(type) {
      let compoundOptions = [];

      switch (type) {
        case 'Carbonyl':
          compoundOptions = this.carbonylCompoundOptions;
          break;
        case 'Alcohol':
          compoundOptions = this.alcoholCompoundOptions;
          break;
      }

      return compoundOptions;
    },
    reset() {
      this.fehlingTestVisible = false;
      this.fehlingButtonDisabled = false;
      this.tollenTestVisible = false;
      this.tollenButtonDisabled = false;
      this.iodoformTestVisible = false;
      this.iodoformButtonDisabled = false;
      this.lucasTestVisible = false;
      this.lucasButtonDisabled = false;
      this.bordwellWellmanTestVisible = false;
      this.bordwellWellmanButtonDisabled = false;

      this.inputs.type1 = null;
      this.inputs.type2 = null;
      this.inputs.type3 = null;
      this.inputs.type4 = null;
      this.inputs.type5 = null;
      this.inputs.type6 = null;

      this.inputs.compound1 = null;
      this.inputs.compound2 = null;
      this.inputs.compound3 = null;
      this.inputs.compound4 = null;
      this.inputs.compound5 = null;
      this.inputs.compound6 = null;

      this.testCounter = 1;
    },
  },
};
</script>
<style scoped>
.cuny-sandbox-container :global(.v-btn) {
  font-family: Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 16px;
  font-weight: bold;
}

.available-tests {
  margin-bottom: 0.75rem;
  line-height: 1.125;
  font-size: 1.25rem;
  font-weight: bold;
}

.test-pool {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 0.75rem;
}

.test-pool-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 1.25rem;
}

.pool-group {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  padding: 0 0.625rem 0.75rem 0.625rem;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}

.group-title {
  font-size: 1rem;
  line-height: 1;
  background: white;
  padding: 0 0.25rem;
  margin: -0.5rem 0 0 -0.25rem;
}

.group-items {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.group-items > * {
  margin: 0 !important;
}

.cuny-sandbox-table-wrapper {
  overflow-x: auto;
  margin: 0 -0.5rem;
}

.cuny-sandbox-table {
  text-align: left;
  border-collapse: collapse;
  font-size: 14px;
}

.cuny-sandbox-table:not(.flexible) {
  table-layout: fixed;
  width: 100%;
}

.cuny-sandbox-table th,
.cuny-sandbox-table td {
  padding: 0.375rem;
}

.cuny-sandbox-table th:first-child,
.cuny-sandbox-table td:first-child {
  padding-left: 0.5rem;
}

.cuny-sandbox-table th:last-child,
.cuny-sandbox-table td:last-child {
  padding-right: 0.5rem;
}

.cuny-sandbox-table .zebra-row td {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.cuny-sandbox-table .zebra-row:nth-of-type(2n) td {
  background-color: #f9f9f9;
}

.cuny-sandbox-table .response-table-heading th {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding-top: 2rem;
}

.cuny-sandbox-table >>> .v-text-field__details {
  display: none !important;
}

.cuny-sandbox-table >>> .v-input__slot {
  margin: 0 !important;
}

.cuny-sandbox-table
  >>> .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.cuny-sandbox-table >>> .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 8px !important;
}

.cuny-sandbox-table >>> .v-text-field .v-input__append-inner {
  padding-left: 0 !important;
}

.cuny-sandbox-table >>> .v-input__icon {
  width: 18px;
  min-width: 18px;
}

.cuny-sandbox-table >>> .v-text-field--outlined fieldset {
  padding-left: 4px;
}

.cuny-sandbox-table .table-heading {
  text-align: left;
  padding-top: 1rem;
  line-height: 1.125;
  font-size: 1.25rem;
  font-weight: bold;
}

.cuny-sandbox-table .test-tube-heading {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.cuny-sandbox-table .test-tube-heading.text-left {
  text-align: left;
}

.cuny-sandbox-table .test-row-heading {
  font-weight: bold;
  white-space: nowrap;
}

.cuny-sandbox-table .text-sm {
  font-size: 12px;
  text-align: center;
}

.cuny-sandbox-table .select-cell {
  font-size: 14px;
  font-weight: bold;
}

.table-icon.check-bold {
  color: #16a34a;
}

.table-icon.close-thick {
  color: #dc2626;
}

.table-icon.minus {
  color: #6b7280;
}

.select-text {
  white-space: nowrap;
}
</style>
